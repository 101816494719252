body {
  margin: 0;
  font-family: Inter;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none !important;
  color: #000 !important;
}

a :hover {
  text-decoration: none !important;
  color: #000 !important;
}

a :after {
  text-decoration: none !important;
  color: #5D9386 !important;

}

a :active {
  text-decoration: none !important;
  color: #000 !important;

}